<template>
  <div style="height:100%">
    <el-row class="toolbar" v-if="showToolbar">
        <el-button v-if="fastAdd" @click="addRecord" plain size="small" icon="el-icon-plus">
          {{ $locale.main.button.add }}
        </el-button>
    </el-row>
    <el-table
      :indent="0"
      class="registry custom_scrollbar edit_column_btn"
      v-loading="loading"
      :show-summary="showSummary"
      :summary-method="getSummaries"
      sum-text=" "
      :data="data.records"
      stripe
      border
      :height="'calc(100% - (' + (showToolbar ? '62' : '32') + 'px - ' + (showPagination ? '0' : '32') + 'px))'"
      style="width: 100%;"
      ref="table"
      @selection-change="selectionChange"
      row-key="id"
      @header-dragend="changeWidth"
      @cell-click="cellClick"
      @row-dblclick="handleRowDblClick"
      highlight-current-row
      :header-cell-style="createdStyleHeader"
    >
      <template slot="empty">
        <span> </span>
      </template>
      <columns
        :isSelectable="isSelectable"
        :isGrouped="false"
        :showButton="showButton"
        @edit-record="$emit('edit-record', $event)"
        @update-layout="updateLayout"
        @row-edit="rowEditHandler"
        @update-editable-model="updateEditableModel"
        :columns="columns"
      ></columns>
    </el-table>
    <el-pagination
      v-if="showPagination"
      @current-change="handleCurrentChange"
      :page-size="pageLimitRegistry"
      layout="total, prev, pager, next"
      :total="data.count">
    </el-pagination>
    <slot></slot>
  </div>
</template>

<script>
import Columns from '@/components/Registry/Columns'
export default {
  name: 'a-simple-table',
  components: {
    Columns
  },
  inject: {
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },
  props: {
    editorAlias: {
      type: String,
      description: 'Псевдоним'
    },
    fastAdd: {
      type: Boolean
    },
    columns: {
      type: Array,
      default () { return [] }
    },
    isSelectable: {
      type: Boolean,
      default: true
    },
    loader: {
      type: Function
    },
    showPagination: {
      type: Boolean,
      default: true
    },
    showSummary: {
      type: Boolean
    },
    cellClickPlugin: {
      type: String,
      default: null
    },
    styleTableHeader: {
      type: String,
      default: () => {
        return 'font-style: normal; font-weight: normal; font-size: 13px; line-height: 20px; word-break: break-word; color: #807265'
      }
    },
    showButton: {
      type: Object
    }
  },
  data () {
    return {
      loading: false,
      selected: [],
      pageLimitRegistry: this.pageLimit || 100,
      editableModel: {},
      data: {
        records: [],
        count: 0
      }
    }
  },
  computed: {
    showToolbar () {
      if (this.fastAdd) {
        return true
      }
      return false
    },
    createdStyleHeader () {
      if (this.styleTableHeader) {
        const str = this.styleTableHeader
        const properties = str.split('; ')
        // const obj = {}
        // properties.forEach(property => {
        //   const val = property.split(': ')
        //   obj[val[0]] = val[1]
        // })
        // return obj
        let style = properties.reduce((acc, item) => {
          acc[item.split(': ')[0]] = item.split(': ')[1]
          return acc
        }, {})
        return style
      } else {
        return ''
      }
    }
  },
  watch: {
    loader () {
      this.loadData()
    },
    'data.records' () {
      this.$emit('input', this.data.records)
    },
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        const values = data.map(item => Number(item[column.property]))
        const header = this.columns.find((item) => item.value === column.property)
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          if (header.type === 'progressField') {
            sums[index] = null
            // sums[index] = `${(sums[index] / values.length).toFixed()} %`
          } else {
            const sumText = sums[index]
            const sumLength = (sumText.toString().includes('.')) ? (sumText.toString().split('.').pop().length) : (0)
            if (sumLength > 2) {
              sums[index] = sums[index].toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            } else {
              sums[index] = sums[index].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
          }
        }
      })

      return sums
    },
    async cellClick (row, column, cell) {
      if (this.cellClickPlugin) {
        let plugin = null

        try {
          // @ts-ignore
          plugin = await submodule.getPlugin(this.cellClickPlugin)
        } catch (error) {
          try {
            plugin = await import(`@/plugins/${this.$config.project}/${this.cellClickPlugin}`)
          } catch (error) {
            console.log(`Plugin: ${this.cellClickPlugin} not found`)
          }
        }

        // eslint-disable-next-line new-cap
        let instance = new plugin.default(this)
        instance.execute(row, column, cell)
      }
    },
    changeWidth (newWidth, oldWidth, column, event) {
      if (!column.property) {
        console.warn('атрибут столбца не указан', column.property)
      }
      this.columns = this.findAndChangeInTree(this.columns, column.property, newWidth)
    },
    findAndChangeInTree (tree, findValue, changeValue) {
      tree.forEach((item) => {
        if (item.value === findValue) {
          item.width = changeValue
        }
        if (Array.isArray(item.children)) {
          item.children = this.findAndChangeInTree(item.children, findValue, changeValue)
        }
      })
      return tree
    },
    getData () {
      return this.data.records
    },
    async loadData () {
      if (this.loader) {
        this.loading = true
        let data = await this.loader()
        if (!data) {
          this.loading = false
          this.updateLayout()
          return false
        }
        data = data.data
        if (typeof data.data !== 'undefined') {
          this.data.records = data.data
          this.data.count = data.count || data.data.length
        } else {
          this.data.records = data
          this.data.count = data.length
        }
        this.updateLayout()
        this.loading = false
      }
    },
    selectionChange (val) {
      this.selected = val
    },
    addRecord () {
      let model = {}
      this.columns.forEach((item) => {
        if (typeof model[item.value] === 'undefined') {
          this.$set(model, item.value, null)
        }
      })
      this.data.records.push(model)
      this.handleRowDblClick(model)
      this.updateLayout()
    },
    handleRowDblClick (row) {
      if (this.editableModel) {
        this.$set(this.editableModel, 'isEdit', false)
      }
      this.$set(this, 'editableModel', row)
      this.$set(row, 'isEdit', true)
    },
    updateLayout () {
      this.$nextTick(() => {
        this.$refs.table && this.$refs.table.doLayout()
        console.log('update layout table')
      })
    },
    rowEditHandler (row) {
      this.$set(row, 'isEdit', false)
      if (Object.keys(this.editableModel).length <= 1) {
        this.editableModel = {}
        return false
      }
      this.updateLayout()
    },
    updateEditableModel (data) {
      this.$set(this.editableModel, data.name, data.value)
    },
    handleCurrentChange (val) {
      val--
      this.currentPage = (val * this.pageLimitRegistry)
      this.loadData()
    }
  }
}
</script>

<style scoped>

</style>
